import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import "twin.macro"

const NotFoundPage = () => {
  // { data, location }
  // const siteTitle = data.site.siteMetadata.title

  return (
    <Container>
      <h1 tw="mb-4 max-w-3xl text-3xl font-bold lg:mb-9 lg:text-5xl">
        Fehler 404 Seite nicht gefunden
      </h1>
      <div tw="grid grid-cols-12 gap-y-10 lg:gap-5">
        <div
          tw="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4 xl:col-start-3"
          className="wp-content"
        >
          <p>
            Leider ist ein Fehler aufgetreten: Die gewünschte Seite wurde nicht
            gefunden.
          </p>
          <p>
            Haben Sie sich vertippt oder eine alte URL aufgerufen? Wenn nicht,
            informieren Sie bitte die Bundesstiftung Gleichstellung per E-Mail.
          </p>
          <p>
            Um zu der vorherigen Seite zurück zu kehren, verwenden Sie bitte die
            „Zurück”-Taste Ihres Browsers.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default NotFoundPage

export const Head = () => {
  return (
    <>
      <title>Fehler 404 Seite nicht gefunden</title>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
